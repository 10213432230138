import { store } from 'src/core/store';
import { Report, ReportData } from 'src/types/Report';
import { ISelectedClientSourceData } from 'src/types/User';
import { ApiReportData, GetReportConfigResponse, LoadReportDataResponse } from '../apiTypes/Report';
import { getDataApiClientInstance, getAdminClientInstance } from '../utils/clientAdapters';
import { formatFiltersV4 } from '../Tranformers';
import type { AdminApiWrapper } from '../apiTypes/Common';

export const getSelectedClientSourceData = (source: string) => {
  const user = store.getState().auth.user;
  let selectedClientSourceData: ISelectedClientSourceData;
  user?.clients?.find(({ es_aliases, ...sourceData }) => {
    if (es_aliases?.some(({ name }) => name === source))
      selectedClientSourceData = { ...sourceData, es_alias: es_aliases.find(({ name }) => name === source) };
  });
  return selectedClientSourceData;
};

export const getReportConfiguration = async (id: number) => {
  try {
    const key = 'reports/';
    const result = (await getAdminClientInstance('getReportConfiguration').get<GetReportConfigResponse>(key + id)).data.data;
    const config = result.configuration;
    return config.report_type === 'DOC' || !getSelectedClientSourceData(config.source)
      ? result
      : { ...result, selectedClientSourceData: getSelectedClientSourceData(config.source) };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const loadReportData = async (id: number, data?: ReturnType<typeof formatFiltersV4>['conditions']['interval']) => {
  try {
    const key = `v4/reports/${id}`;
    const result = (await getDataApiClientInstance(key).post<LoadReportDataResponse>(key, { interval: data })).data;
    return result.reportType === 'DOC' ? result : ({ ...result, selectedClientSourceData: getSelectedClientSourceData(result.source) } as ReportData);
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const editReport = async (reportId: number, reportData: ApiReportData) => {
  try {
    const key = `reports/${reportId}`;
    return (await getAdminClientInstance(key).put(key, reportData)).data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const createReport = async (reportData: ApiReportData) => {
  try {
    const key = 'reports';
    return (await getAdminClientInstance(key).post<AdminApiWrapper<{ dashboard_id: number }>>(key, reportData)).data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const duplicateReport = async (reportId: number) => {
  try {
    const key = `reports/${reportId}/duplicate`;
    return (await getAdminClientInstance('duplicateReport').post<AdminApiWrapper<Pick<Report, 'id' | 'name'>>>(key)).data.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const deleteReport = async (id: number) => {
  try {
    const key = `reports/${id}`;
    return (await getAdminClientInstance(key).delete(key)).data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};
