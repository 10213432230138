import { IUser, IUserToken } from 'src/types/User';
import { IFetchUserResponse, ILoginResponse } from './apiTypes/User';
import { getAdminClientInstance, getAdminClientInstanceWthToken } from './utils/clientAdapters';
import { submitUserSurveyApi } from './UserSurvey';
import { validateErrorResponse } from './utils';
export const getUserDetails: (createClientCalled?: boolean) => Promise<{ user: IUser }> = async (createClientCalled) => {
  const createClientKey = 'user/client';
  try {
    const response = await getAdminClientInstance('user').get<IFetchUserResponse>('user');
    const user = response.data.data.user;
    user.is_survey_submitted = response.data.data.is_survey_submitted;
    if (user.clients?.length === 0 && !createClientCalled) {
      await getAdminClientInstance(createClientKey).post(createClientKey, { email: user.email });
      return getUserDetails(true);
    }
    user.clients
      .map((client) => client.es_aliases)
      .flat()
      .forEach((esAlias) => {
        esAlias.dimensions = esAlias.dimensions.sort((a, b) => (['topic', 'diy_topic', 'themes'].includes(a.name) ? -1 : 1));
      });
    return { user };
  } catch (error) {
    throw validateErrorResponse(error);
  }
};

export const ssoLoginApi = async (tokens: IUserToken) => {
  try {
    await getAdminClientInstanceWthToken('ssoLogin', tokens.accessToken).post<ILoginResponse>('auth/login?sso=true');
  } catch (error) {
    throw validateErrorResponse(error);
  }
};

export const getSSOProvider = async (email: string): Promise<string> => {
  const key = 'sso';
  try {
    const response = await getAdminClientInstance(key).post(key, { data: { email } });
    return response.data.data.sso;
  } catch (error) {
    if (error?.response?.data?.message === 'User does not exist.') return 'default';
    throw new Error(error?.response?.data?.message);
  }
};

export const registerApi = async (name: string, email: string, password: string, marketing: boolean) => {
  const key = 'user/register';
  const surveyData = { 9: marketing };
  try {
    const response = await getAdminClientInstance(key).post(key, { name, email, password });
    await submitUserSurveyApi({ email, submission: surveyData }, 'signup');
    return response.data.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};
