import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { api } from '../api';
import { cloneDeep } from 'lodash';
import { removeUnderscore, addSpaceAtCamelCasePosition } from '../utils/stringReplaceOperations';
import { TimeSeriesInitialTypes } from './types/timeseries';
import { fetchConversations, fetchConversationsMetadataField } from 'src/api/conversation';
export const fetchConversationsMetadataFields = createAsyncThunk<any, any>('fecthConversationsMetadataFields', ({ source }) =>
  fetchConversationsMetadataField({ source })
);

export const fetchConvdata = createAsyncThunk('timeseries/fetchConvdata', fetchConversations);

export const updateTags = createAsyncThunk<any, any>('timeseries/updateTags', async ({ source, conversationId, updatedTopics }) => {
  await api.updateTopics(source, conversationId, updatedTopics);
  return { conversationId, updatedTopics };
});

const initialState: TimeSeriesInitialTypes = {
  convdata: null,
  selectedConvViewTabId: 'ticket',
  isConvViewTabDisplayed: false,
  totalConversationCount: 0,
  convdataFetchStatus: 'idle',
  convdataFetchMore: true,
  sentiTagModalOpen: false,
  convdataPage: null,
  convdataPreviousPage: null,
  conversationsMetadataFieldList: [],
  fetchConversationsMetadataFieldsSource: '',
  fetchConversationsMetadataFieldsStatus: 'idle',
  showConvTranslatedMessage: false,
};

const TimeseriesSlice = createSlice({
  name: 'timeseries',
  initialState: {
    ...initialState,
  },
  reducers: {
    resetTimeseriesReduxState: (state) => {
      Object.assign(state, {
        ...initialState,
      });
    },
    resetConvdata: (state) => {
      state.convdata = null;
      state.totalConversationCount = 0;
      state.convdataPage = null;
      state.convdataPreviousPage = null;
      state.convdataFetchStatus = 'idle';
      state.convdataFetchMore = true;
    },

    updateShowConversationMessage: (state, action) => {
      state.showConvTranslatedMessage = action.payload.showConvTranslatedMessage;
    },
    toggleUpdateTagModal: (state) => {
      state.sentiTagModalOpen = !state.sentiTagModalOpen;
    },
    updateConversationsMetadataFieldList: (state, action) => {
      state.conversationsMetadataFieldList = action.payload;
    },
    updateSelectedConvViewTabId: (state, action: PayloadAction<TimeSeriesInitialTypes['selectedConvViewTabId']>) => {
      state.selectedConvViewTabId = action.payload;
    },
    updateIsConvViewTabDisplayed: (state, action) => {
      state.isConvViewTabDisplayed = action.payload;
    },
    updateConversations: (state, action) => {
      const { convdata } = action.payload;
      state.convdataFetchStatus = 'fulfilled';
      state.convdata = convdata;
      state.totalConversationCount = convdata?.total ?? 0;
      state.convdataPage = action.payload.page + 1;
      state.convdataPreviousPage = action.payload.page;
      state.convdataFetchMore = convdata?.next && convdata?.conversations?.length > 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConvdata.pending, (state) => {
        state.convdataFetchStatus = 'pending';
      })
      .addCase(fetchConvdata.fulfilled, (state, action) => {
        const { convdata } = action.payload;
        convdata.conversations = [...(state.convdata?.conversations ?? []), ...convdata.conversations];
        state.convdataFetchStatus = 'fulfilled';
        state.convdata = convdata;
        state.totalConversationCount = convdata?.total ?? 0;
        state.convdataPage = action.payload.page + 1;
        state.convdataPreviousPage = action.payload.page;
        state.convdataFetchMore = convdata?.next && convdata?.conversations?.length > 0;
      })
      .addCase(fetchConvdata.rejected, (state) => {
        state.convdataFetchStatus = 'rejected';
      })
      .addCase(updateTags.fulfilled, (state, action) => {
        let updatedConvdata = cloneDeep(state.convdata);
        if (updatedConvdata && updatedConvdata.conversations) {
          for (let conv of updatedConvdata.conversations) {
            if (conv.id === action.payload.conversationId) {
              conv.sentisumTags = action.payload.updatedTopics;
              break;
            }
          }
        }

        state.convdata = updatedConvdata;
      })
      .addCase(fetchConversationsMetadataFields.pending, (state) => {
        state.fetchConversationsMetadataFieldsStatus = 'pending';
        state.fetchConversationsMetadataFieldsSource = '';
        state.conversationsMetadataFieldList = [];
      })
      .addCase(fetchConversationsMetadataFields.fulfilled, (state, action) => {
        const metadataList = action.payload.data
          .sort((a, b) => a.field_name.localeCompare(b.field_name))
          .map((metadata, index) => {
            metadata.label = removeUnderscore(addSpaceAtCamelCasePosition(metadata.field_name));
            metadata.checked = false;
            metadata.index = index;
            return metadata;
          });

        state.conversationsMetadataFieldList = metadataList;
        state.fetchConversationsMetadataFieldsStatus = 'fulfilled';
        state.fetchConversationsMetadataFieldsSource = action.payload.source;
      })
      .addCase(fetchConversationsMetadataFields.rejected, (state) => {
        state.fetchConversationsMetadataFieldsStatus = 'rejected';
        state.fetchConversationsMetadataFieldsSource = '';
        state.conversationsMetadataFieldList = [];
      });
  },
});

const persistConfig = {
  key: 'timeseries',
  storage,
  whitelist: [],
};

export const {
  resetTimeseriesReduxState,
  resetConvdata,
  toggleUpdateTagModal,
  updateConversationsMetadataFieldList,
  updateSelectedConvViewTabId,
  updateShowConversationMessage,
  updateIsConvViewTabDisplayed,
  updateConversations,
} = TimeseriesSlice.actions;
export default persistReducer(persistConfig, TimeseriesSlice.reducer);
// export default TimeseriesSlice.reducer;
